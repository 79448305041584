



<template>
  <b-container class="pt-5 bg-blue text-white fontStandard" fluid>
    <!-- <template>
  <b-container class="bg-blue fontStandard">
    <h1 class="text-center pt-5">
      <i class="big_text fas fa-user-cogs pb-1" />
      <br />

      <b-badge pill variant="light">
        <strong class="fontStandard text-primary">
          <BTextUppercase>En développement</BTextUppercase>
        </strong>
      </b-badge>
    </h1>

    <b-form-group>
      <b-button type="submit" variant="primary" block>En développement</b-button>
    </b-form-group>
  </b-container>
    </template>-->

    <h3 class="text-center">
      <i class="big_text fas fa-user pb-1" />
      <br />

      <b-badge pill variant="light">
        <strong class="fontStandard text-primary">
          <BTextUppercase>En développement</BTextUppercase>
        </strong>
      </b-badge>
    </h3>
    <b-row>
      <b-col cols="12">
        <!--  -->

        <!--  -->
      </b-col>
    </b-row>
  </b-container>
</template>






<script>
import { mapState } from "vuex";

export default {
  components: { BTextUppercase: () => import("@/components/BTextUppercase") },
  mixins: [],
  computed: {
    ...mapState(["account", "events"]),
  },
};
</script>
